import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { OfferHeader } from '../../components/OfferHeader/OfferHeader';
import { Header } from '../../../../components/Header';
import styles from './OfferPage.module.scss';
import { DEFAULT_UNIVERSITY_EMOJI } from '../../../../common/constants';
import { HeaderType } from '../../../../components/Header/Header';
import { OfferItemInfo } from '../../interfaces/filter-item.interface';
import { modifyStringToShortView } from '../../utils/modify-string-to-short-view';
import { getMinShortNameOfUniversity } from '../../utils/get-min-short-name-of-university';
import { getSpecialityEmojiById } from '../../utils/get-speciality-emoji-by-filter-id';
import { getOfferByIdReq } from '../../../../api/offers';
import { Bullet } from '../../components/Bullet/Bullet';
import { useOffersContext } from '../../layouts/OffersContext';
import { OfferDetails } from '../../components/OfferDetails/OfferDetails';
import { OfferPageTitle } from '../../components/OfferPageTitle/OfferPageTitle';

export const OfferPage = () => {
  const { handleGoBack } = useOffersContext();
  const { id, u_id } = useParams();

  const [offer, setOffer] = useState<OfferItemInfo>();

  let facultyName;
  let shortUniversityName;
  let specialityEmoji;

  if (offer?.name) {
    facultyName = modifyStringToShortView(offer?.program_name);
    shortUniversityName = offer?.u_short_name
      ? getMinShortNameOfUniversity(offer?.u_short_name)
      : getMinShortNameOfUniversity(offer.name);
    specialityEmoji = getSpecialityEmojiById(offer?.speciality_code);
  }

  const fetchOfferItem = async () => {
    try {
      if (id && u_id) {
        const offerInfo = await getOfferByIdReq(id, u_id);

        if (offerInfo) {
          setOffer(offerInfo);
        } else {
          handleGoBack();
        }
      }
    } catch (error) {
      console.error('Error fetching offer info:', error);
    }
  };

  useEffect(() => {
    fetchOfferItem();
  }, [id]);

  return (
    <>
      <Header type={HeaderType.LIGHT} />
      {offer ? (
        <div className={styles.offerPageWrap}>
          <div className={styles.headerWrap}>
            <OfferHeader {...offer} />
            <div className={styles.titleWrap}>
              <div className={styles.name}>
                {offer?.name} ({offer?.faculty_id})
              </div>
              <div className={styles.descWrap}>
                <div className={`${styles.itemRow} ${styles.firstItemRow}`}>
                  <div>Бакалавр</div>
                  <Bullet />
                  <div>{offer?.id}</div>
                </div>
                <div className={`${styles.itemRow} ${styles.secondItemRow}`}>
                  <Bullet />
                  <div className={styles.universityNameWrap}>
                    <div className={styles.emoji}>{DEFAULT_UNIVERSITY_EMOJI}</div>
                    <div>{shortUniversityName}</div>
                  </div>
                  <Bullet />
                  <div className={styles.facultyNameWrap}>
                    <div className={styles.emoji}>{specialityEmoji}</div>
                    <div>{facultyName}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.contentWrap}>
            <div className={styles.detailsWrap}>
              <OfferPageTitle>Деталі</OfferPageTitle>
              <OfferDetails {...offer} />
            </div>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};
