import styles from './OfferDetails.module.scss';
import { education_forms } from '../../filters/education_forms';

export const OfferDetails = ({ ...offer }: any) => {
  const formattedPrice = new Intl.NumberFormat().format(offer.price);
  const formOfEducation = education_forms.find((item) => item.id === offer.education_form_id)?.name;
  return (
    <div className={styles.offerDetailsWrap}>
      <div className={styles.contentWrap}>
        <div className={styles.itemWrap}>
          <div className={styles.itemTitle}>
            <h4>ВНЗ</h4>
          </div>
          <div className={styles.offerDescr}>{offer.u_short_name}</div>
        </div>
        <div className={styles.itemWrap}>
          <div className={styles.itemTitle}>
            <h4>Факультет</h4>
          </div>
          <div className={styles.offerDescr}>{offer.program_name}</div>
        </div>
        <div className={styles.itemWrap}>
          <div className={styles.itemTitle}>
            <h4> вартість навчання</h4>
          </div>
          <div className={styles.offerDescr}>{`${formattedPrice} грн/рік`}</div>
        </div>
        <div className={styles.itemWrap}>
          <div className={styles.itemTitle}>
            <h4>Форма навчання</h4>
          </div>
          <div className={styles.offerDescr}>{formOfEducation}</div>
        </div>
        <div className={styles.itemWrap}>
          <div className={styles.itemTitle}>
            <h4>кількість місць</h4>
          </div>
          <div
            className={styles.offerDescr}
          >{`${offer.enrolled_budget_n} місць бюджет, ${offer.enrolled_contract_n} місць контракт`}</div>
        </div>
      </div>
    </div>
  );
};
