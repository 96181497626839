import { useMemo } from 'react';
import { useOffersContext } from '../../../layouts/OffersContext';
import { Subject, subjects } from '../../../filters/subjects';
import { ModalFilters } from '../../ModalFilters/ModalFilters';
import { SubjectCheckbox } from '../../SubjectCheckbox/SubjectCheckbox';
import { ZnoModalBase } from '../../ZnoModalBase/ZnoModalBase';

export const SelectSubjectModal = ({
  isOpen,
  handleModal,
  handle,
  closeStep,
}: {
  isOpen: boolean;
  handleModal: () => void;
  handle: () => void;
  closeStep: () => void;
}) => {
  const { handleSelectOfZnoSubjects, znoScores } = useOffersContext();

  const selectableSubjects = useMemo(() => {
    return subjects.filter((subject) => ![100, 101, 103, 104, 105].includes(subject.id));
  }, []);

  return (
    <ModalFilters isOpen={isOpen} handle={handleModal}>
      <ZnoModalBase
        title='Обери свої предмети ЗНО 📚'
        nextTitle='Далі'
        step='1'
        handle={handle}
        closeStep={closeStep}
        disabled={!Object.keys(znoScores.data).length}
      >
        {selectableSubjects.map((subject: Subject) => (
          <SubjectCheckbox
            key={subject.id}
            subjectName={subject.subjectName}
            handleZnoScores={() => handleSelectOfZnoSubjects(subject.id)}
            isChecked={!!znoScores.data[subject.id]}
          />
        ))}
      </ZnoModalBase>
    </ModalFilters>
  );
};
